import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, BindingEngine, observable, TaskQueue } from 'aurelia-framework';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ServiceStationService } from 'services/service-station-service';
import { ToastService } from 'services/toast-service';
import { UserService } from './../../services/user-service';
import { StorageSetTimeDialog } from './storage-set-time-dialog';
import { NetStorageService, UpdateNetStorageLocationDto } from 'services/net-storage-service';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { I18N } from 'aurelia-i18n';

declare const Draggable;

@autoinject
export class StorageView {
  private serviceStationId: number;
  public serviceStation: Models.ServiceStation;
  public pageSize: number = 30;
  @bindable currentPage: number = 1;
  private serviceStations: Array<Models.ServiceStation>;
  private sortable: any;

  private nets: Models.Net[];

  public storageFilters: any;
  public filterGetFiltersQuery: any;
  public filterToggleVisible: any;
  public filterClearAll: any;
  public storageFields: any[];

  private keepMenuOpen: boolean = true;

  // NETS AND SPECIAL PRODUCTS
  private netFilters: any;
  @observable({ changeHandler: 'netFiltersReady' })
  private netFilterGetFiltersQuery: any;
  public netFilterToggleVisible: any;
  public netFilterClearAll: any;
  private fields: any;
  private context: any;
  private isSpecialProductList: boolean = true;
  public customerId;

  @observable public netsCurrentPage: number = 1;
  public netsPageSize: number = 20;
  public netsTotalItems: number;

  public storageView: Models.StorageViewPipeline[];
  public itemsInStorage: Models.StorageViewPipelineItem[] = [];
  public itemsInStorageIds: number[] = [];
  public itemsOutsideStorage: Models.StorageViewPipelineItem[] = [];
  public itemsOutsideStorageIds: number[] = [];

  private netsSearchText: string;
  private searchTextSubscription: Subscription;

  private pipelineSearchInputHasFocus: boolean = false;
  private pipelineFilters: {
    searchText: string;
  } = {
    searchText: '',
  };

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private serviceStationService: ServiceStationService,
    private toastService: ToastService,
    private userService: UserService,
    private netService: NetService,
    private bindingEngine: BindingEngine,
    private dialogService: DialogService,
    private taskQueue: TaskQueue,
    private netStorageSerivce: NetStorageService,
    private confirmation: ConfirmDialog,
    private t: I18N
  ) {
    const keepMenuOpenValue = localStorage.getItem('KEEP_MENU_OPEN') === 'false' ? false : true;
    this.keepMenuOpen = keepMenuOpenValue;
  }

  private attached() {
    this.bindingEngine.propertyObserver(this.pipelineFilters, 'searchText').subscribe((newValue, oldValue) => {
      this.filterPipelineServices(newValue, oldValue);
    });

    Promise.all([this.userService.getCurrentUser(), this.serviceStationService.getAllCached()]).then((responses) => {
      let user = responses[0];
      this.serviceStationId = user.ServiceStationId;

      this.serviceStations = responses[1].filter((x) => !x.IsDeleted).sort((a, b) => (a.Name > b.Name ? 1 : -1));

      if (!this.serviceStationId) {
        this.serviceStationId = this.serviceStations[0].Id;
        this.serviceStation = this.serviceStations[0];

        this.currentPage = 1;
      } else {
        let serviceStation = this.serviceStations.find((x) => x.Id === this.serviceStationId);
        this.serviceStationId = serviceStation.Id;
        this.serviceStation = serviceStation;
      }
    });

    this.handleIfSidebarIsOpen();

    const resizeBar = document.getElementById('resize-bar');
    resizeBar.addEventListener('mousedown', this.resizeServicePrequeueVertically());
  }

  private resizeServicePrequeueVertically() {
    const servicePrequeue = document.getElementById('service-prequeue-id');
    let offsetY;
    let startY;
    let startH;
    let maxY;
    let maxH;
    function dragMouseDown(e) {
      if (e.button !== 0) return;
      e = e || window.event;
      e.preventDefault();
      const { clientY } = e;
      startY = parseInt(window.getComputedStyle(servicePrequeue).getPropertyValue('top'));
      startH = parseInt(window.getComputedStyle(servicePrequeue).getPropertyValue('height'));

      offsetY = clientY - startY;
      maxY = startY + startH - 35;
      maxH = maxY - 190;

      document.addEventListener('mouseup', closeDragElement, false);
      document.addEventListener('mousemove', elementDrag, false);
    }

    function elementDrag(e) {
      const { clientY } = e;

      let y = clientY - offsetY;
      let h = startH + startY - y;

      if (h < 35) h = 35;
      if (y > maxY) y = maxY;

      if (y > 225) {
        servicePrequeue.style.top = y + 'px';
      } else {
        servicePrequeue.style.top = 225 + 'px';
      }
      if (h > 35) {
        if (h > maxH) {
          servicePrequeue.style.height = maxH + 'px';
        } else {
          servicePrequeue.style.height = h + 'px';
        }
      } else {
        servicePrequeue.style.height = 35 + 'px';
      }
    }

    function closeDragElement() {
      document.removeEventListener('mouseup', closeDragElement);
      document.removeEventListener('mousemove', elementDrag);
    }
    return dragMouseDown;
  }

  handleIfSidebarIsOpen() {
    const servicePrequeueId = document.getElementById('service-prequeue-id');

    if (this.keepMenuOpen) {
      servicePrequeueId.classList.add('service-prequeue--fixed');
      servicePrequeueId.classList.remove('service-prequeue--fixed-closed');
    }
  }

  async netFiltersReady(value) {
    if (value !== undefined) {
      await this.getStorageView();
    }
  }

  public getFilterKey() {
    return 'STORAGE_VIEW';
  }

  public getStorageFilterKey() {
    return 'STORAGE_VIEW_STORAGE';
  }

  private getFields() {
    return [
      {
        field: 'Id',
        title: 'general.id',
        selected: true,
        disabled: true,
        visible: false,
      },
      {
        field: 'NetIdentifier',
        title: 'net.netidentifier',
        selected: true,
        disabled: false,
      },
      {
        field: 'CustomerNetId',
        title: 'net.customerNetId',
        selected: false,
        disabled: false,
      },
      {
        field: 'CustomerName',
        title: 'general.customer',
        selected: !this.context || this.context.indexOf('customer-list') < 0,
        disabled: false,
      },
      {
        field: 'NetShapeName',
        title: 'notlogg.netshape',
        selected: true,
        disabled: false,
      },
      {
        field: 'NetTypeName',
        title: 'notlogg.netType',
        selected: true,
        disabled: false,
      },
      {
        field: 'Circumference',
        title: 'net.circumference',
        selected: true,
        disabled: false,
      },
      {
        field: 'NettingTypeName',
        title: 'net.nettingTypeName',
        selected: true,
        disabled: false,
      },
      {
        field: 'MeshSize',
        title: 'notlogg.meshsize',
        selected: true,
        disabled: false,
      },
      {
        field: 'ThreadType',
        title: 'nettingtype.threadtype',
        selected: true,
        disabled: false,
      },
      {
        field: 'NylonType',
        title: 'nettingtype.nylonType',
        selected: true,
        disabled: false,
      },
      {
        field: 'SpecialProductTypeName',
        title: 'notlogg.specialproducttype',
        selected: true,
        disabled: false,
      },
      {
        field: 'SpecialProductCircumference',
        title: 'specialProduct.Circumference',
        selected: true,
        disabled: false,
      },
    ];
  }

  private async getNets() {
    if (this.fields == null) {
      this.fields = this.getFields();
    }

    if (!this.searchTextSubscription) {
      this.searchTextSubscription = this.bindingEngine
        .propertyObserver(this, 'netsSearchText')
        .subscribe((newValue, oldValue) => {
          if (newValue !== oldValue && !!this.netFilters) {
            this.currentPage = 1;
            this.netFilters.skip = 0;
            this.getNets();
          }
        });
    }

    let netFiltersQuery: any = await this.netFilterGetFiltersQuery(this.fields);

    netFiltersQuery._export = false;
    netFiltersQuery._select = this.fields
      .filter((x) => x.selected)
      .map((x) => x.field)
      .join(',');

    netFiltersQuery.top = this.netsPageSize;
    netFiltersQuery.skip = this.netFilters.skip;
    netFiltersQuery.orderBy = this.netFilters.orderBy;
    netFiltersQuery.orderByDirection = this.netFilters.orderByDirection;
    netFiltersQuery.searchText = this.netsSearchText;
    netFiltersQuery.storageView = true;

    this.netService
      .getList(netFiltersQuery)
      .then((res) => {
        res.json().then((data) => {
          const finalData = data.map((it) => {
            const netting = `${it?.ThreadType ?? ''} ${it?.NylonType ?? ''} ${it?.MeshSize ?? ''}`;
            it.Netting = netting.replace('---', '').replace('  ', '');
            return it;
          });

          this.nets = finalData.filter((it) => !this.itemsInStorageIds.includes(it.Id));

          this.updateItemsOutsideStorageList();
          this.netsTotalItems = +res.headers.get('x-total-count');

          setTimeout(() => {
            this.setupDraggable();
          });
        });
      })
      .catch((err) => {
        this.errorService.handleError(err);
      });
  }

  async getStorageView() {
    const query = await this.filterGetFiltersQuery();
    const pipelines: Models.StorageViewPipeline[] = await this.netService.getStorageView(
      this.serviceStationId,
      query.customerIds,
      query.storageIds,
      query.showSpecialProducts,
      query.onlyNetsWithoutActiveService
    );

    this.storageView = pipelines;
    this.updateItemsInStorageList();

    await this.getNets();

    setTimeout(() => {
      this.setupDraggable();
    });
  }

  setupDraggable() {
    if (this.sortable) {
      this.sortable.destroy();
    }

    const sortables = document.querySelectorAll('[sortable]');
    this.sortable = new Draggable.Sortable(sortables, {
      draggable: '[sortitem]',
      distance: 50,
    });

    this.sortable.on('sortable:stop', async (event) => {
      const netId = event.dragEvent.source.children[0].attributes['data-id'].value;
      const isInStorage = event.newContainer.hasAttribute('instorage');
      let newPipelineId = event.newContainer.id;

      const newSortIndex = event.newIndex + 1;

      if (newPipelineId === '') {
        newPipelineId = null;
      } else {
        newPipelineId = parseInt(newPipelineId);
      }

      // find the item we have moved
      const pipelineItem =
        this.itemsInStorage.find((pi) => pi.Id === +netId) ?? this.itemsOutsideStorage.find((pi) => pi.Id === +netId);

      if (!pipelineItem) {
        throw new Error('Net not found');
      }

      if (
        isInStorage === !!pipelineItem.ServiceStationStorageId &&
        newPipelineId === pipelineItem.ServiceStationStorageId &&
        (!isInStorage || newSortIndex === pipelineItem.SortIndex)
      ) {
        // if we clicked and started dragging/sorting, but ended up in the same position,
        // just return without running any updates
        return;
      } else if (!pipelineItem.ServiceStationStorageId && newPipelineId === '') {
        // sorting within the prequeue is not supported, so cancel the event..
        // TODO: This doesnt really work, but it doesnt really matter much anyway
        event.cancel();
        return;
      }

      pipelineItem.ServiceStationStorageId = newPipelineId;
      pipelineItem.SortIndex = newSortIndex;

      // Try to get current location...
      const { data } = await this.netStorageSerivce.getCurrentStorage(pipelineItem.Id);
      if (data && this.serviceStationId !== data.ServiceStationId) {
        // If the net is stored on another service station we need to confirm the move.
        const lane = this.storageView.find((x) => x.ServiceStationStorageId == pipelineItem.ServiceStationStorageId);

        const description = this.t.tr('storage.moveToNewServiceStationDescription', {
          service_station: data.ServiceStationName,
          storage: data.StorageName,
          new_service_station: this.serviceStation?.Name ?? '-',
          new_storage: lane?.Name ?? '-',
        });

        const approve = await this.confirmation.confirmYesNo('storage.moveToNewServiceStation', description);

        // No move.. exit and refresh the view so move is undone.
        if (!approve) {
          this.refreshStorageView();
          return;
        }
      }

      try {
        if (pipelineItem.ServiceStationStorageId) {
          const dto = new UpdateNetStorageLocationDto();
          Object.assign(dto, {
            ServiceStationStorageId: pipelineItem.ServiceStationStorageId,
            SortIndex: pipelineItem.SortIndex ?? 1,
            NetId: pipelineItem.Id,
          });
          await this.netStorageSerivce.updateStorage(dto);
        } else {
          await this.netStorageSerivce.removeFromStorage(pipelineItem.Id);
        }

        await this.refreshStorageView();
        this.toastService.showSuccess('net.updated');

        await this.dialogService.open({
          viewModel: StorageSetTimeDialog,
          restoreFocus: () => {
            void this.refreshStorageView();
          },
          model: {
            item: pipelineItem,
          },
        });
      } catch (error) {
        this.errorService.handleError(error);
      }
    });
  }

  updateItemsInStorageList() {
    let allItems = [];
    let allItemsIds = [];

    this.storageView.forEach((pipeline) => {
      if (pipeline.Data?.length > 0) {
        allItems = [...allItems, ...pipeline.Data];
        allItemsIds = [...allItemsIds, ...pipeline.Data.map((it) => it.Id)];
      }
      if (pipeline.Pipelines?.length > 0) {
        pipeline.Pipelines.forEach((childPipeline) => {
          if (childPipeline.Data?.length > 0) {
            allItems = [...allItems, ...childPipeline.Data];
            allItemsIds = [...allItemsIds, ...childPipeline.Data.map((it) => it.Id)];
          }
        });
      }
    });

    this.itemsInStorage = allItems;
    this.itemsInStorageIds = allItemsIds;
  }

  updateItemsOutsideStorageList() {
    const netsAsPipelineItems = this.nets.map((n: any) => {
      return {
        Id: n.Id,
        NetIdentifier: n.NetIdentifier,
        CustomerName: n.CustomerName,
        ServiceStationStorageId: null,
        SortIndex: n.SortIndex ?? null,
      } as Models.StorageViewPipelineItem;
    });

    this.itemsOutsideStorage = netsAsPipelineItems;
    this.itemsOutsideStorageIds = netsAsPipelineItems.map((it) => it.Id);
  }

  async refreshStorageView() {
    const query = await this.filterGetFiltersQuery();
    const pipelines: Models.StorageViewPipeline[] = await this.netService.getStorageView(
      this.serviceStationId,
      query.customerIds,
      query.storageIds,
      query.showSpecialProducts,
      query.onlyNetsWithoutActiveService
    );

    this.storageView = pipelines;

    // Recreate Draggable
    setTimeout(() => {
      this.setupDraggable();
    });

    this.updateItemsInStorageList();
  }

  public detached() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  }

  public netsCurrentPageChanged(newValue, oldValue) {
    if (parseInt(newValue, 10) === parseInt(oldValue, 10) || !this.netFilters) {
      return;
    }

    this.netFilters.skip = (+newValue - 1) * (this.netFilters.top ?? this.netsPageSize);

    void this.getNets();
  }

  private closeDropdown() {
    this.eventAggregator.publish('dropdownClose', 1);
  }

  changeServiceStation(serviceStation) {
    this.serviceStationId = serviceStation.Id;
    this.serviceStation = serviceStation;

    this.currentPage = 1;

    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = null;
    }

    this.taskQueue.queueMicroTask(() => {
      this.eventAggregator.publish('storageFilterOptionsRefresh', 'customerIds');
      this.eventAggregator.publish('storageFilterReset', 'showSpecialProducts');

      this.eventAggregator.publish('netFilterOptionsRefresh', 'customerIds');
      this.eventAggregator.publish('netFilterReset', 'showSpecialProducts');
      this.eventAggregator.publish('netFilterReset', 'industryId');

      this.closeDropdown();

      setTimeout(() => {
        void this.getStorageView();
      }, 10);
    });
  }

  setPipelineSearchFocus() {
    this.pipelineSearchInputHasFocus = true;
  }

  filterPipelineServices(newvalue: string, oldvalue: string) {
    this.storageView.forEach((x) => {
      x.Data.forEach((element) => {
        element._isFilteredOut = false;
      });
    });

    const filterWithOutSpace = this.replaceAll(newvalue, ' ', '').toLowerCase();
    const filterLower = newvalue.toLowerCase();
    this.storageView.forEach((x) => {
      x.Data.forEach((x) => {
        if (
          x.CustomerName.toLowerCase().indexOf(filterLower) == -1 &&
          this.replaceAll(x.NetIdentifier, ' ', '').toLowerCase().indexOf(filterWithOutSpace) == -1
        ) {
          x._isFilteredOut = true;
        }
      });
    });
  }

  private replaceAll(str, find, replace): string {
    return str.replace(new RegExp(find, 'g'), replace);
  }
}
